
import {defineComponent, onMounted, onUnmounted} from "vue";
import {Actions} from "@/store/enums/store.enums";
import {useStore} from "vuex";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "invoice",
  components: {},
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "print-content-only");
      setCurrentPageBreadcrumbs("General", "Invoice");
    });

    const print = () => {
      window.print();
    };

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "print-content-only");
    });

    return {
      print,
    };
  },
});
